import {useState, useEffect, useRef, useCallback} from "react";

import '../../tost.css';
import "animate.css";
import { cssTransition, toast } from 'react-toastify';
import { BiRupee } from "react-icons/bi";
import { BsCurrencyDollar } from "react-icons/bs";
//import moment from 'moment';
import { format } from 'date-fns'

import {Links} from 'routes/PathLink'
import { IoStar, IoStarOutline, IoStarHalf } from "react-icons/io5";

export const admin_phone = '+917387869666';

export const LIMIT = 12
export const cod_charge = 45;
export const LINK_EXPIRE = 3
export const register_fee = 5000
export const register_fee_hospital = 5000
export const register_valid = 6 //6=6 month
export const renew_per = 10 //reg fee will increase by 10% for renew
export const bookingamount = 50000;


export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const APP_URL = process.env.REACT_APP_URL
export const APP_NAME = process.env.REACT_APP_NAME
export const APP_SLOGAN = process.env.REACT_APP_SLOGAN
export const IMG_URL = process.env.REACT_APP_IMG_URL
export const RAZOR_KEY = process.env.REACT_RAZOR_KEY
export const RAZOR_JS = "https://checkout.razorpay.com/v1/checkout.js"
export const APP_LOGO = "https://i.imgur.com/3g7nmJC.jpg"
export const FOOTER_SITE = "JOCOJO";


export const THEME = "#892DA0"

export const snackBarOptions = {
   position: 'bottom-right',
   style: {
     backgroundColor: '#0cb339',
     color: '#FFF',
     textAlign: 'center',
     fontSize:'16px'
   },
   closeStyle: {
     color: '#FFF',
     fontSize: '16px',
   },
}

export const bounce = cssTransition({
	enter: "animate__animated animate__bounceIn",
	exit: "animate__animated animate__bounceOut"
});

const tost_anim = cssTransition({
	enter: "swirl-in-fwd",
	exit: "swirl-out-bck"
});

export const toast_anim = (message) => {
   toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      transition: tost_anim,
      autoClose: 2000
   });
}
export const toast_anim_error = (message) => {
   toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000,
   });
}

export const replaceWithBr = (str) => {
   return str.replace(/\n/g, "<br />")
}

export const useStateCallback = (initialState) => {
   const [state, setState] = useState(initialState);
   const cbRef = useRef(null); // init mutable ref container for callbacks
 
   const setStateCallback = useCallback((state, cb) => {
     cbRef.current = cb; // store current, passed callback in ref
     setState(state);
   }, []); // keep object reference stable, exactly like `useState`
 
   useEffect(() => {
     // cb.current is `null` on initial render, 
     // so we only invoke callback on state *updates*
     if (cbRef.current) {
       cbRef.current(state);
       cbRef.current = null; // reset callback after execution
     }
   }, [state]);
 
   return [state, setStateCallback];
}

export const loadScript = (src) => {
   return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
         resolve(true);
      };
      script.onerror = () => {
         resolve(false);
      };
      document.body.appendChild(script);
  });
}

export const date_format = (date) => {
   return format(new Date(date), 'dd MMM, yyyy');
}

export const amount_format = (amt) => {
   //return amt.toLocaleString(undefined, { minimumFractionDigits: 2 })
   return <div className="flex items-center"><BiRupee className="-mt-1" />{Number(amt).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
}

export const digital_amount_format = (amt) => {
   //return amt.toLocaleString(undefined, { minimumFractionDigits: 2 })
   return <div className="flex items-center"><BsCurrencyDollar className="-mt-1" />{Number(amt).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
}

export const rating_format = (amt) => {
   //return amt.toLocaleString(undefined, { minimumFractionDigits: 2 })
   return <div className="flex items-center">{Number(amt).toLocaleString(undefined, { minimumFractionDigits: 1 })}</div>
}

export const gateway_amount = (amt) => {
   return Number(amt)*100;
}

export const random_str = (length) => {
   let len = Number(length) + 2;
   return (Math.random() + 1).toString(36).slice(2, len);
}

export const remove_overflow = () => {
   let cartDrawer = document.querySelector(".cart-drawer");
   let body = document.querySelector("body");
   cartDrawer?.remove();
   body.classList.remove("overflow-hidden");
}

export const discount = (max, min) => {
   
   max = Number(max)
   min = Number(min)

   if(max <= 0 || min <= 0){
      return; 
   }
   
   let def = max - min;
   let disc = (def/max)*100
   disc = Math.round(disc);
   return disc
}

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export const queryStringToJSON = (queryString) => {
   if(queryString.indexOf('?') > -1){
     queryString = queryString.split('?')[1];
   }
   var pairs = queryString.split('&');
   var result = {};
   pairs.forEach(function(pair) {
     pair = pair.split('=');
     result[pair[0]] = decodeURIComponent(pair[1] || '');
   });
   return result;
}

export const addDays = (date, days) => {

   return date.setDate(date.getDate() + parseInt(days));
   
};


export const showRating = (rating) => {
   let children = []
   let rate = Number(rating.toString().split('.')[1]);
   let ratingP = Number(rating)
   if(rate > 2){
      ratingP = Number(rating) + 1
   }
   //console.log('rating=======:',rate)
   //Inner loop to create children
   for (let j = 1; j <= 5; j++) {
      if(j <= ratingP){
            if(j <= rating){
               children.push(<IoStar className="w-4 h-4 text-yellow-400" />)
            }else{
               children.push(<IoStarHalf className="w-4 h-4 text-yellow-400" />)
            }
      }else{
            children.push(<IoStarOutline className="w-4 h-4 text-yellow-400" />)
      }
   }
   return children
} 

export const getproductUrl = (item) =>{
     let prdurl = '';
     prdurl = `/p/${item.product_slug}`;
     /*if(item?.mainslug){
        prdurl = `${Links.CATEGORY}/${item?.mainslug ? item?.mainslug :'p'}?p=${item.sku}`;
       
     }else{
        
       
     }
     */
    return prdurl;
}

export const getGroupUrl = (item) =>{
     let prdurl = '';
     if(item?.slug){
        prdurl = `${Links.CATEGORY}/${item?.slug}`;  
    }
    return prdurl;
}


 export const pngjpgonly = {'image/jpeg': [],
         'image/png': []
   };

export const pdfdoconly = {'application/pdf': [],
         'application/msword': []
   };

export const truncate = (str, max, len) => {
    return str.length > max ? str.substring(0, len) + "..." : str;
}

export const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

export const editedFiles = {'application/pdf': [],
   'application/msword': [],
   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
};



